<div class="modal-container h-100">
  <div class="data-container">
    <div class="title-wrapper d-flex align-content-center">
      <p>
        {{
          isPrivateSellingEnabled
            ? 'Manage your private selling campaign'
            : 'Start a private selling campaign'
        }}
      </p>
      <button
        type="button"
        class="btn-modal-header"
        (click)="onDismiss()"
        data-dismiss="modal">
        <span class="ti ti-close"></span>
      </button>
    </div>
    <div
      class="content-wrapper gap-5 d-flex flex-column justify-content-center align-items-center align-content-center text-center my-4">
      <div class="bulk-edit-title d-flex flex-column text-start gap-sm-4 mb-0">
        <div>
          A private selling campaign allows you to
          <b>
            lock the events to anyone that is not included in the allowlist </b
          >. All events discoverable within the portal will require the user to
          login with their MLB credentials in order to buy tickets.
          <br />
          <br />

          To proceed with starting a private selling campaign, please provide
          the system with <b> at least one patron identificator </b> within the
          field below. <br />
        </div>

        <tagify
          name="tagify-element"
          class="tags-textarea"
          [formControl]="form"
          inputClass="w-100 d-flex justify-content-start align-items-start"
          [settings]="settings"></tagify>

        <div class="d-flex justify-content-between">
          <div
            class="patron-ids-disclaimer d-flex flex-column justify-content-start">
            <div>
              * Any changes to the list will not be persisted until you hit the
              save button.
            </div>
            <div>* Clearing the list is irreversible.</div>
          </div>
          <div>
            <div class="d-flex gap-1">
              <button
                (click)="onSaveWhitelist()"
                [disabled]="!isSaveEnabled() || isLoading()"
                [class.off]="!isSaveEnabled() || isLoading()"
                type="submit"
                name="form"
                class="default-2 d-flex justify-content-center align-content-center">
                @if (isLoading()) {
                  <span class="loader"></span>
                } @else {
                  Save changes
                }
              </button>
              <button
                (click)="onClearWhitelist()"
                [class.off]="!form.value?.length"
                type="submit"
                name="form"
                class="default-2 red">
                Clear
              </button>
            </div>
          </div>
        </div>
        <div
          class="d-flex mb-0 gap-5 flex-column justify-content-around align-content-center align-items-center">
          <div class="w-100 mb-0 d-flex justify-content-center gap-5">
            @if (isPrivateSellingEnabled) {
              <button
                (click)="onFinishCampaignConfirm()"
                type="submit"
                name="form"
                class="default-2 red">
                Finish private selling
              </button>
            } @else {
              <button
                (click)="onStartCampaignConfirm()"
                [class.off]="!form.value?.length"
                type="submit"
                name="form"
                class="default-2">
                Start private selling
              </button>
            }
            <button (click)="onDismiss()" class="default-4">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
