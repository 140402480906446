import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TagifyService, TagifySettings } from 'ngx-tagify';
import { ConfigurationService } from '../../../services/configuration.service';

import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import { PrivateSellingWhitelist } from '../../models/client.model';
import { PrivateSellingService } from '../../services/private-selling.service';
interface TagifyValue {
  value: string;
  __isValid: boolean;
  __tagId: string;
}

export type PrivateSellingWhitelistClient = Pick<
  PrivateSellingWhitelist,
  'patronId' | 'tagId' | 'clientId'
>;

@Component({
  selector: 'app-manage-private-selling',
  templateUrl: './manage-private-selling.component.html',
  styleUrls: ['./manage-private-selling.component.scss'],
})
export class ManagePrivateSellingComponent implements OnInit {
  @Input() clientId: number;
  @Input() whitelist: PrivateSellingWhitelistClient[];
  @Input() isPrivateSellingEnabled: boolean;

  @Output() modifiedWhitelist = new EventEmitter<
    Partial<PrivateSellingWhitelist>[]
  >();

  private bsModalRef = inject(BsModalRef);
  private toastr = inject(ToastrService);
  private tagifyService = inject(TagifyService);
  public configurationService = inject(ConfigurationService);
  private privateSellingService = inject(PrivateSellingService);

  protected form = new FormControl<TagifyValue[]>([]);
  protected isSaveEnabled = signal(false);
  protected isLoading = signal(false);

  ngOnInit() {
    this.initializeWhitelist();
    this.subscribeToFormChanges();
    this.onEditList();
  }

  private initializeWhitelist() {
    const formattedWhitelist = this.whitelist.map(whitelistInstance => ({
      __isValid: true,
      __tagId: whitelistInstance.tagId,
      value: whitelistInstance.patronId.toString(),
    }));

    this.form.setValue(formattedWhitelist);
  }

  private subscribeToFormChanges() {
    this.form.valueChanges.subscribe({
      next: newForm => {
        if (!newForm) {
          this.whitelist = [];
          return;
        }

        this.addNewPatronIdsToWhitelist(newForm);
        this.removeStalePatronIdsFromWhitelist(newForm);
        this.onEditList();
      },
      error: error => {
        this.toastr.error(error);
      },
    });
  }

  private addNewPatronIdsToWhitelist(newForm: TagifyValue[]) {
    for (const formValue of newForm) {
      const isPatronIdInWhitelist = this.whitelist.some(
        el => el.patronId === +formValue.value
      );
      if (!isPatronIdInWhitelist) {
        this.whitelist.push({
          clientId: this.clientId,
          patronId: +formValue.value,
          tagId: formValue.__tagId,
        });
      }
    }
  }

  private removeStalePatronIdsFromWhitelist(newForm: TagifyValue[]) {
    for (let i = this.whitelist.length - 1; i >= 0; i--) {
      const whitelistValue = this.whitelist[i];
      const isPatronIdInForm = newForm.some(
        el => +el.value === whitelistValue.patronId
      );

      if (!isPatronIdInForm) {
        this.whitelist.splice(i, 1);
      }
    }
  }

  protected onStartCampaignConfirm = () => {
    const whitelistData = this.getWhitelistData();
    if (!whitelistData.length) return;
    const formattedWhitelist = whitelistData.map(data => ({
      ...data,
      patronId: +data.patronId,
    }));
    this.privateSellingService
      .enablePrivateSelling(formattedWhitelist)
      .subscribe({
        next: () => {
          this.isPrivateSellingEnabled = true;
          this.privateSellingService.privateSellingToggleEvent.emit(true);
          this.toastr.info('Private selling campaign succesfully started.');
        },
        error: error => {
          this.toastr.error(error);
        },
      });
  };

  protected onFinishCampaignConfirm = () => {
    this.privateSellingService.disablePrivateSelling().subscribe({
      next: () => {
        this.isPrivateSellingEnabled = false;
        this.privateSellingService.privateSellingToggleEvent.emit(false);
        this.toastr.info('Private selling campaign succesfully finished.');
      },
      error: error => {
        this.toastr.error(error);
      },
    });
  };

  protected onEditList = (): void => {
    const haveDifferentValues =
      this.privateSellingService.checkForPatronIdChanges(this.whitelist);
    this.isSaveEnabled.set(haveDifferentValues);
  };

  protected onSaveWhitelist = () => {
    const whitelistData = this.getWhitelistData();
    if (!whitelistData.length) return;

    this.isLoading.set(true);
    const formattedWhitelist = whitelistData.map(data => ({
      ...data,
      patronId: +data.patronId,
    }));
    this.privateSellingService
      .updateWhitelist(formattedWhitelist)
      .pipe(finalize(() => this.isLoading.set(false)))
      .subscribe({
        next: () => {
          this.privateSellingService.initialWhitelist.set([...this.whitelist]);
          this.isSaveEnabled.set(false);
          this.toastr.info('Allowlist succesfully saved.');
        },
        error: error => {
          this.toastr.error(error);
        },
      });
  };

  private getWhitelistData() {
    const form = this.form.value || [];
    if (!form.length) {
      this.toastr.error(
        'No patron identificators selected to start the private selling campaign.'
      );
      return [];
    }
    return form.map(({ value, __tagId }) => ({
      patronId: value,
      tagId: __tagId,
    }));
  }

  protected onClearWhitelist = () => {
    this.privateSellingService.deleteWhitelist().subscribe({
      next: () => {
        this.tagifyService.get('tagify-element').removeAllTags();
        this.form?.reset();
        this.privateSellingService.initialWhitelist.set([...[]]);
        this.whitelist = [...[]];
        this.modifiedWhitelist.emit([]);
        this.isSaveEnabled.set(false);
        this.toastr.info('Allowlist succesfully cleared.');
      },
      error: error => {
        this.toastr.error(error);
      },
    });
  };

  private onInvalidTag = (
    event: CustomEvent<Tagify.InvalidTagEventData<Tagify.TagData>>
  ) => {
    let message = '';
    if (event.detail.message === 'already exists') {
      message = 'This patron identificator already exists in your allowlist.';
    } else {
      message =
        'Invalid patron identificator format. Please make sure to insert a valid number.';
    }
    this.toastr.error(message);
  };

  protected onDismiss() {
    this.bsModalRef.hide();
  }

  protected settings: TagifySettings = {
    placeholder: 'Press enter to insert...',
    trim: true,
    duplicates: false,
    delimiters: /,|\s|\n/, // Comma, spaces, and newline as delimiters
    pasteAsTags: true,
    keepInvalidTags: false,
    editTags: {
      clicks: 1,
    },
    pattern: /^[0-9]+$/,
    callbacks: {
      invalid: this.onInvalidTag,
    },
  };
}
